@import 'mixins';

.content {
    background-image: url(/assets/images/404.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    @include breakpoint('sm') {
        padding: 50px 0 80px;
    }

    @include breakpoint('md') {
        padding: 100px 0 200px;
    }

    .content-wrapper {
        max-width: 640px;
        margin: 0 auto;

        .breadcrumbs {
            @include breakpoint('sm') {
                display: none;
            }
        }

        .heading {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__text {
                font-family: Jaguar Cyrillic;
                color: #005a70;
                font-size: 78px;
                line-height: 95px;

                @include breakpoint('sm') {
                    font-size: 100px;
                    line-height: 120px;
                }

                &_red {
                    color: #eb154c;
                }
            }

            &__curve {
                margin-top: -46px;
                display: none;

                @include breakpoint('sm') {
                    display: block;
                }
            }
        }

        .message {
            display: none;
            font-size: 25px;
            font-weight: 500;
            line-height: 35px;
            @include breakpoint('sm') {
                display: block;
            }
        }

        .message-mobile {
            display: flex;
            flex-direction: column;

            .input {
                margin: 10px 0 40px;
            }

            .button {
                margin-top: 10px;
            }

            @include breakpoint('sm') {
                display: none;
            }
        }
    }
}
